@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

body{
    font-family: "Outfit";
}


.custom-nav-btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.6); /* Semi-transparent background */
    color: white;
    border-radius: 50%; /* Rounded buttons */
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 10;
  }
  
  .swiper-button-prev {
    left: -15px; /* Adjust position as needed */
  }
  
  .swiper-button-next {
    right: -15px; /* Adjust position as needed */
  }
  
  .custom-nav-btn:hover {
    background-color: rgba(0, 0, 0, 0.8); /* Darken on hover */
  }
  